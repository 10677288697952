import image1 from '../images/confirmed/1.jpg';
import image2 from '../images/confirmed/2.jpg';
import image3 from '../images/confirmed/3.jpg';
import image4 from '../images/confirmed/4.jpg';
import image5 from '../images/confirmed/5.jpg';
import image6 from '../images/confirmed/6.jpg';
import image7 from '../images/confirmed/7.jpg';
import image8 from '../images/confirmed/8.jpg';
import image9 from '../images/confirmed/9.jpg';
import image10 from '../images/confirmed/10.jpg';
import image11 from '../images/confirmed/11.jpg';
import image12 from '../images/confirmed/12.jpg';
import image13 from '../images/confirmed/13.jpg';
import image14 from '../images/confirmed/14.jpg';



import i1 from '../images/common/1.png';
import i2 from '../images/common/2.png';
import i3 from '../images/common/3.png';


export const confirmed = [
    image1, image2, image3, image4, image5, image6, image7, image8, image9, image10,
    image11, image12, image13, image14
];

export const common = [
    i1, i2, i3
];